import React from "react"
import { FaCity, FaBuilding, FaHome, FaPhotoVideo, FaSchool, FaMobileAlt, FaUserCog, FaPager, FaCogs } from 'react-icons/fa'
import { GoVersions } from 'react-icons/go'
import { AiFillNotification } from 'react-icons/ai'
import { CheckCircle } from "react-feather"
const user = JSON.parse(sessionStorage.getItem('user'))

const navigationAdmin = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  }, {
    id: "listPost",
    title: "Listar Noticias",
    type: "item",
    icon: <AiFillNotification size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/list-post"
  }, {
    id: "institutions",
    title: "Instituciones",
    type: "collapse",
    icon: <FaBuilding size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "department",
        title: "Departamentos",
        type: "item",
        icon: <CheckCircle size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/department"
      }, {
        id: "city",
        title: "Ciudades",
        type: "item",
        icon: <CheckCircle size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/city"
      }, {
        id: "institution",
        title: "Instituciones",
        type: "item",
        icon: <CheckCircle size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/institution"
      },
    ]
  }, {
    id: "video",
    title: "Vídeos",
    type: "item",
    icon: <FaPhotoVideo size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/video"
  }, {
    id: "user",
    title: "Usuarios",
    type: "item",
    icon: <FaUserCog size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/user"
  }, {
    id: "specialDays",
    title: "Días especiales",
    type: "collapse",
    icon: <FaMobileAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "specialDayMovil",
        title: "Móvil",
        type: "item",
        icon: <CheckCircle size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/specialdaymobil"
      }, {
        id: "specialDayWeb",
        title: "Web",
        type: "item",
        icon: <CheckCircle size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/specialdayweb"
      }
    ]
  }, {
    id: "versionapp",
    title: "Versión app",
    type: "item",
    icon: <GoVersions size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/versionapp"
  }, {
    id: "domain",
    title: "Dominios",
    type: "item",
    icon: <FaCogs size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/domain"
  }
]

const navigatorEditor = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  }, {
    id: "listPost",
    title: "Listar Noticias",
    type: "item",
    icon: <AiFillNotification size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/list-post"
  }, {
    id: "video",
    title: "Vídeos",
    type: "item",
    icon: <FaPhotoVideo size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/video"
  }, {
    id: "specialDayMovil",
    title: "Días especiales",
    type: "item",
    icon: <FaMobileAlt size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/specialdaymobil"
  }, {
    id: "specialDayWeb",
    title: "Días especiales",
    type: "item",
    icon: <FaPager size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/specialdayweb"
  }
]

const navigatorSoporte = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  }, {
    id: "department",
    title: "Departamentos",
    type: "item",
    icon: <FaBuilding size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/department"
  }, {
    id: "city",
    title: "Ciudades",
    type: "item",
    icon: <FaCity size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/city"
  },
  {
    id: "institution",
    title: "Instituciones",
    type: "item",
    icon: <FaSchool size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/institution"
  }, {
    id: "versionapp",
    title: "Versión app",
    type: "item",
    icon: <GoVersions size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/versionapp"
  }, {
    id: "domain",
    title: "Dominios",
    type: "item",
    icon: <FaCogs size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/domain"
  }
]

const navigationConfig =
  user?.profile === 1 ? navigationAdmin :
    user?.profile === 2 ? navigatorEditor :
      user?.profile === 3 ? navigatorSoporte :
        []

export default navigationConfig
